import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {UncontrolledCarousel, Button, Card, CardBody, UncontrolledAlert, Spinner, Container} from 'reactstrap';
import logo1 from '../../img/logo1.png';
import logo2 from '../../img/logo2.png';
import logo3 from '../../img/logo3.png';
import logo4 from '../../img/logo4.png';
import logo5 from '../../img/logo5.png';
import opfor from '../../img/opfor.png';
import {getTrackerToken} from "../../utils/GetToken";
import MatchHistory from "../MatchHistory";
import TopServers from "./TopServers";
import PlayersIcon from "../SVG/PlayersIcon";
import ServerIcon from "../SVG/ServerIcon";

export default function Home() {
  const [sessionData, setSessionData] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [playerCount , setPlayerCount] = useState(0);
  const [isLoading, setLoading] = useState(true);
  
  useEffect(() => {
    async function fetchData() {
      try {
        let token = getTrackerToken();
        
        const sessionsData = await fetch(`api/session`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(r => r.json());
        
        const serverData = await fetch(`api/servers/top`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(r => r.json());
        
        const playerCount = await fetch(`api/data-overview/players`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(r => r.json());
        
        setSessionData(sessionsData);
        setServerData(serverData);
        setPlayerCount(playerCount);
      }
      catch (ex) {
        console.log(ex)
      }
    }
    fetchData().then(() => setLoading(false));
  }, []);
  
  return (
    <>
    <div>
      <UncontrolledAlert color="primary" style={{textAlign: 'center', marginBottom: '0', borderRadius: '0px'}}>
        <a href="https://aao25.com/forum/news-82/(beta)-gaining-honor-and-honor-claims" target="_blank" rel="noopener noreferrer">[NEWS] Gaining honor and honor claims</a>
      </UncontrolledAlert>
      <div className="carousel-container">
        <UncontrolledCarousel
            controls={false}
            items={[
              {
                key: 1,
                altText: ' ',
                caption: ' ',
                src: logo1,
              },
              {
                key: 2,
                altText: ' ',
                caption: ' ',
                src: logo2,
              },
              {
                key: 3,
                altText: ' ',
                caption: ' ',
                src: logo3,
              },
              {
                key: 4,
                altText: ' ',
                caption: ' ',
                src: logo4,
              },
              {
                key: 5,
                altText: ' ',
                caption: ' ',
                src: logo5,
              }
            ]}
        />
        <div id='intro'>
          <div id='intro-welcome'>
            <h1 className='heading'>Welcome to <span className="yellow gunplay" style={{fontWeight: 'normal'}}>AATracker.net</span></h1>
          </div>
          <p className="intro-message">AATracker.net is a community project currently tracking {playerCount.playerCount === 0 ? <Spinner size="sm" color="light" /> : <span className='yellow gunplay'>{playerCount.playerCount}</span>} America's Army players!.</p>
          <div id='intro-buttons'>
            <Link to="/players">
              <Button
                  style={{marginRight: 10}}
                  color="primary"
                  tag="a"
                  size="lg"
              >
                <PlayersIcon/>
                <span className="m-2">Players</span>
              </Button>
            </Link>
            <Link to="/servers">
              <Button
                  color="primary"
                  tag="a"
                  size="lg">
                <ServerIcon/>
                <span className="m-2">Servers</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Container>
        <div className="d-flex home-container">
          <div className="d-flex flex-column flex-grow-1">
            <Card className="mt-3" style={{borderRadius: '10px', flex: '1 1 auto'}}>
              <CardBody style={{zIndex: '3'}}>
                <div className='center'>
                  <h2 className='sub-heading yellow gunplay' style={{fontWeight: 'normal'}}>RECENT MATCHES</h2>
                  {isLoading ?
                      <Spinner className='center mt-2' color="light"/>
                      :
                      <>
                        <MatchHistory sessionData={sessionData}/>
                      </>
                  }

                </div>
              </CardBody>
            </Card>
            <Card className="mt-3" style={{borderRadius: '10px', flex: '1 1 auto'}}>
              <CardBody className="text-center">
                <h2 className='sub-heading yellow gunplay' style={{textAlign: 'center', marginBottom: '-0.5rem', fontWeight: "normal"}}>SERVERS</h2>
                <div className='servers-container'>
                  {isLoading ?
                      <Spinner className='center mt-4' color="light" />
                      :
                      <TopServers serverData={serverData}/>
                  }
                </div>
                <Link style={{fontSize: '0.75em', fontWeight: 'normal'}} to="/servers">View All</Link>
              </CardBody>
            </Card>
          </div>
          <Card className="mt-3 ml-1r" style={{ borderRadius: '10px', minWidth: '15rem', flex: '1 1 auto' }}>
            <CardBody className="d-flex flex-column align-items-center"
                      style={{textAlign: 'center'}}>
              <h2 className='sub-heading yellow gunplay' style={{fontWeight: 'normal'}}>LINKS</h2>
              <div className="d-flex justify-content-center flex-column align-items-center h-100">
                <img src={opfor} alt="opfor character" className="opfor-img"/>

                <h2 className='sub-heading' style={{zIndex: '3'}}>Play America's Army</h2>
                <p className="pt-2" style={{zIndex: '3'}}><a href='https://aao25.com/forum/' target="_blank"
                                                             rel="noopener noreferrer">Download
                  2.5 Assist</a></p>
                <h2 className='sub-heading mt-3' style={{zIndex: '3'}}>Join us on Discord!</h2>
                <p className="pt-2" style={{zIndex: '3'}}><a href="https://discord.aatracker.net" target="_blank"
                                                             rel="noopener noreferrer">Join
                  our Discord</a></p>
                <h2 className='sub-heading mt-3' style={{zIndex: '3'}}>Helpful Links</h2>
                <ul className="pt-2" style={{listStyle: 'none', padding: '0', zIndex: '3'}}>
                  <li><a href='https://auth.aa25.org/register/' target="_blank" rel="noopener noreferrer">Register a 2.5
                    Assist account</a>
                  </li>
                  <li><a href='https://auth.aa25.org/namechange/' target="_blank" rel="noopener noreferrer">Change your
                    in-game name</a>
                    <li><a href="https://auth.aa25.org/passreset/" target="_blank" rel="noopener noreferrer">Reset your
                      password</a></li>
                  </li>
                </ul>
              </div>


            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
    </>
  );
}
