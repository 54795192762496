import React from 'react';
import { 
    Badge,
    UncontrolledAlert
 } from 'reactstrap';

export default function Badges({isValid, serverData}) {
    if (!isValid || !serverData) return null;
    const playerCount = serverData.status.numplayers;
    if (playerCount < 3 || serverData.status.mapname.endsWith("SFMod")) {
        const message = playerCount < 3 ? "Player stats will not be tracked until a server has at least 3 players in game!." : "This server appears to be running a modded map and will not be tracked.";
        return (
            <>
            <UncontrolledAlert color="primary">
                <p className="mb-0">
                    {message}
                </p>
            </UncontrolledAlert>
            <Badge style={{marginRight: 5}} color="primary">Untracked</Badge>
            </>
        )
    }
    return(
        <>
        <Badge style={{marginRight: 5}} color="primary">Tracked</Badge>
        </>
    )
}