import React from 'react';
import {Spinner} from 'reactstrap';

export default function Loading({message}) {  
    return (
        <div className="spinner-container">
            <div className="loadingMessage">
                {message}
            </div>
            <Spinner animation="border" variant="primary" className="yellow" />
        </div>
    );
  };
