import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {Card, CardBody, Table, UncontrolledTooltip} from 'reactstrap';
import { default as Badges } from './Badges';
import PaginationControl from "../Pagination/PaginationControl";
import LoadingBlur from "../LoadingBlur";
import ServerBlock from "../Home/ServerBlock";

export default function Players({players, playerCount, pageSize, onPaginate, isLoading, isError}) {
    const [sortDirection, setSortDirection] = useState(-1);
    const [sortColumn, setSortColumn] = useState("totalKills");
    const [currentPage, setCurrentPage] = useState(1);

    const handleSort = (column) => {
        let direction = column === sortColumn ? -sortDirection : -1;
        setSortDirection(direction);
        setSortColumn(column);
        let offset = pageSize * (currentPage - 1);
        onPaginate(offset, column, direction);
    }

    const handlePageChange = (pageNumber, totalPages) => {
        if (isLoading || pageNumber > totalPages || pageNumber < 1 || (currentPage === totalPages && pageNumber >= totalPages) || (currentPage === 1 && pageNumber <= 1)) return;
        setCurrentPage(pageNumber);
        let offset = pageSize * (pageNumber - 1);
        onPaginate(offset, sortColumn, sortDirection);
    }
    
    if (isError || !players || players.length === 0) {
        return (
            <div className="center mt-4">
                <h5 style={{color: "#FFF", fontWeight: "bold"}}>Whoops!</h5>
                <h6 style={{color: "#FFF"}}>We were unable to load any America's Army players. Please try again later.</h6>
            </div>
        )
    }

    return (
        <>
            <div className="table-wrapper">
                {isLoading ? <LoadingBlur message={"Loading Players..."}/> : null}
                <Table striped className="text-center">
                    <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th className="sortable" style={{textAlign: "left"}} onClick={() => handleSort("name")}>
                            Name {sortColumn === "name" ? (sortDirection === 1 ? "▲" : "▼") : ""}
                        </th>
                        <th className="sortable" onClick={() => handleSort("totalKills")}>
                            Kills {sortColumn === "totalKills" ? (sortDirection === 1 ? "▲" : "▼") : ""}
                        </th>
                        <th className="sortable" onClick={() => handleSort("totalDeaths")}>
                            Deaths {sortColumn === "totalDeaths" ? (sortDirection === 1 ? "▲" : "▼") : ""}
                        </th>
                        <th className="sortable" onClick={() => handleSort("kdr")}>
                            KDR {sortColumn === "kdr" ? (sortDirection === 1 ? "▲" : "▼") : ""}
                        </th>
                        <th className="sortable" onClick={() => handleSort("honor")}>
                            Honor {sortColumn === "honor" ? (sortDirection === 1 ? "▲" : "▼") : ""}
                        </th>
                        <th>
                            vHonor
                        </th>
                        <th className="sortable" onClick={() => handleSort("totalScore")}>
                            Score {sortColumn === "totalScore" ? (sortDirection === 1 ? "▲" : "▼") : ""}
                        </th>
                        <th className="sortable" onClick={() => handleSort("totalMinutes")}>
                            Time Played {sortColumn === "totalMinutes" ? (sortDirection === 1 ? "▲" : "▼") : ""}
                        </th>
                    </tr>
                    </thead>
                    <tbody style={{filter: isLoading ? 'blur(2px)' : 'none'}}>
                    {players.map((player, index) => {
                        let kdr = player.kdr.toFixed(2);
                        return (
                            <tr key={player.name}>
                                <td style={{textAlign: 'center'}}><Badges player={player} index={index}/></td>
                                <td className="online-leaderboard">
                                    {player.onlineServer ?
                                        <>
                                            <Link to={`/server/${player.onlineServer.address}`}>
                                                <span id={`status-${index}`} className="online-circle" style={{float: 'right', width: '1rem', height: '1rem'}} />
                                            </Link>
                                            <UncontrolledTooltip placement="bottom" target={`status-${index}`} style={{
                                                backgroundColor: 'transparent',
                                                boxShadow: 'none',
                                                maxWidth: '32em',
                                                width: '32em',
                                                textAlign: 'left'
                                            }}>
                                                <ServerBlock key='online' server={player.onlineServer} index='online'
                                                             playerName={player.name}/>
                                            </UncontrolledTooltip>
                                        </>
                                    :
                                        <>
                                            <span id={`status-${index}`} className="offline-circle"
                                                  style={{float: 'right', width: '1rem', height: '1rem'}}/>
                                            <UncontrolledTooltip target={`status-${index}`} placement="bottom">{`${player.name} is offline`}</UncontrolledTooltip>
                                        </>
                                    }
                                </td>
                                <td style={{textAlign: 'left'}}>
                                <Link to={`/player/${encodeURIComponent(player.name)}`}>{player.name}</Link>
                                </td>
                                <td>{player.kills}</td>
                                <td>{player.deaths}</td>
                                <td>
                                    {
                                        player.deaths < 10 ? (
                                            <>
                                                <span id={'kdr' + index}>...</span>
                                                <UncontrolledTooltip
                                                    placement="top"
                                                    target={'kdr' + index}
                                                >
                                                    Sortable KDR calculated at 10 deaths.
                                                </UncontrolledTooltip>
                                            </>
                                        ) : kdr < 0.75 ? (
                                            <span style={{color: '#ff0000'}}>{kdr}</span>
                                        ) : kdr < 1.0 ? (
                                            <span style={{color: '#ff6a00'}}>{kdr}</span>
                                        ) : kdr < 1.75 ? (
                                            <span style={{color: '#fff700'}}>{kdr}</span>
                                        ) : kdr < 2.00 ? (
                                            <span style={{color: '#91ff00'}}>{kdr}</span>
                                        ) : (
                                            <span style={{color: '#13bf00'}}>{kdr}</span>
                                        )
                                    }
                                </td>
                                <td>{player.honor}</td>
                                <td>{player.virtualHonor}</td>
                                <td>{player.score}</td>
                                <td>{`${Math.floor(player.minutesPlayed / 60).toString().padStart(2, '0')}h${(player.minutesPlayed % 60).toString().padStart(2, '0')}m`}</td>
                            </tr>
                        )
                    })}
                    {players.length < pageSize && (
                        [...Array(pageSize - players.length)].map((_, index) => (
                            <tr key={`empty-row-${index}`}>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </Table>
            </div>

            <PaginationControl currentPage={currentPage} totalPages={Math.floor(playerCount / pageSize)}
                               handlePageChange={handlePageChange} isLoading={isLoading}/>
        </>
    );
}