import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, FormFeedback, Spinner } from 'reactstrap';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { getTrackerToken } from '../../utils/GetToken.js';
import logo from '../../img/honor_logo.gif';

function AddServerModal() {
  const [modal, setModal] = useState(false);
  const [ipValid, setIpValid] = useState(false);
  const [ipInvalid, setIpInvalid] = useState(false);
  const [portValid, setPortValid] = useState(false);
  const [portInvalid, setPortInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [formData, setFormData] = useState({
    ip: '',
    port: '',
    captchaToken: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const ipRegex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
    const portRegex = /^[0-9]+$/;
  
    // Check if IP Address is valid
    if (name === "ip") {
      let result = ipRegex.test(value)
      setIpValid(result);
      setIpInvalid(!result);
    } 
  
    // Check if Port is valid
    if (name === "port") {
      let result = portRegex.test(value);
      setPortValid(result);
      setPortInvalid(!result);
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setStatusMessage("Dispatching Mr_Gunman-GB to examine the server...");
    const token = getTrackerToken();
    const response = await fetch('api/servers/addserver', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(formData)
    });
    setLoading(false);
    if (response.ok) {
      setSuccess(true);
      const responseMessage = await response.text().then(s => s.replace(/"/g, ''));
      setStatusMessage(`${responseMessage} was added!`);
    }
    else {
      setSuccess(false);
      const responseMessage = await response.text().then(s => s.replace(/"/g, ''));
      setStatusMessage(`Could not add server: ${responseMessage}`);
    }
  };

  const toggle = () => {
    if (!loading) {
      setModal(!modal);
      setLoading(false);
      setSuccess(false);
      setIpValid(false);
      setIpInvalid(false);
      setPortValid(false);
      setPortInvalid(false);
      setStatusMessage('');
      setFormData({
        ip: '',
        port: '',
        captchaToken: '',
      });
    } 
  };

  const handleCaptchaChange = (token) => {
    setFormData({ ...formData, ["captchaToken"]: token });
  };

  return (
    <div>
      <Button className="mb-3" color="primary" size="sm" onClick={toggle}>
        Add Server
      </Button> 
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Register Honor Server</ModalHeader>
          <ModalBody style={{backgroundColor: 'var(--bs-body-bg)'}}>
          { loading ? (
            <div style={{display: 'block', textAlign: 'center'}}>
                <div className="loadingMessage yellow">
                  {statusMessage}
                </div>
                <Spinner animation="border" variant="primary" />
            </div>
          ) : statusMessage !== "" ? 
            (
              <div style={{display: 'block', textAlign: 'center', marginBottom: '2rem'}}>
                <code style={{color: success ? '#3cf281' : '#e44c55'}}>{statusMessage}</code>
              </div>
            ) 
          : null }
              <Form onSubmit={handleSubmit}>
                  <img src={logo} alt="honor logo" style={{height: '4rem', display: 'block', margin: '0 auto'}}/>
                  <p className='mt-1' style={{fontSize: '0.7rem'}}>Honor forms the basis of Americas Armys unique scoring system. It is modeled after the Army values (Loyalty, Duty, Respect, Selfless-Service, Honor, Integrity and Personal Courage or LeaDeRSHIP).</p>
                  <p className='mt-1' style={{fontSize: '0.7rem'}}>Provide server details below to begin offering honor to your players.</p>
                  <FormGroup>
                      <Label for="ip">IP Address</Label>
                      <Input
                          type="text"
                          name="ip"
                          id="ip"
                          placeholder="IP Address"
                          value={formData.ip}
                          className={ipValid ? "is-valid" : ipInvalid ? "is-invalid" : ""}
                          onChange={handleChange}
                          disabled={loading || success}
                          style={{backgroundColor: "#FFFF", color: isActive ? "#000" : "#000"}}
                          onFocus={() => setIsActive(true)}
                          onBlur={() => setIsActive(false)}
                      />
                      <FormFeedback valid>{formData.ip} is a valid IP address!</FormFeedback>
                      <FormFeedback invalid>{formData.ip} is not a valid IP Address</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                      <Label for="port">Port</Label>
                      <Input
                          type="text"
                          name="port"
                          id="port"
                          placeholder="Port"
                          value={formData.port}
                          className={portValid ? "is-valid" : portInvalid ? "is-invalid" : ""}
                          onChange={handleChange}
                          disabled={loading || success}
                          style={{backgroundColor: "#FFFF", color: isActive ? "#000" : "#000"}}
                          onFocus={() => setIsActive(true)}
                          onBlur={() => setIsActive(false)}
                      />
                      <FormFeedback valid>{formData.port} is a valid port!</FormFeedback>
                      <FormFeedback invalid>{formData.port} is not a valid port</FormFeedback>
                  </FormGroup>
                  <p style={{fontSize: '0.8rem'}}>Cheat servers and private servers will be rejected. Forceclass and mod servers will not return any player stats.
                      Allow up to 5 minutes for the server to appear and begin tracking. Provide public server port rather than query port.</p>
                  <FormGroup style={{marginTop: '2rem', textAlign: 'center'}}>
                      <HCaptcha
                          sitekey={process.env.REACT_APP_SITE_KEY}
                          onVerify={handleCaptchaChange}
                      />
                  </FormGroup>
                  <div style={{textAlign: 'right', marginTop: '2rem'}}>
                      <Button color="danger" onClick={toggle} style={{marginRight: '0.5rem'}}
                              disabled={loading || success}>
                          Cancel
                      </Button>
                      <Button color="primary" type="submit"
                              disabled={!ipValid || !portValid || formData.captchaToken === '' || loading || success}>
                          Add Server
                      </Button>
                  </div>
              </Form>
          </ModalBody>
      </Modal>
    </div>
  );
}


export default AddServerModal;