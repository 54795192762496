import React, { useState, useEffect } from 'react';
import { default as LastSeen } from './LastSeen';
import { default as HonorProgress } from './HonorProgress';
import { default as PlayerListGroup } from './PlayerListGroup';
import { default as TimeChart } from './TimeChart';
import { default as KillsChart } from './KillsChart';
import { default as ScoreChart } from './ScoreChart';
import { default as MapStats } from './MapStats';
import { default as MatchHistory } from '../MatchHistory';
import {
    Card,
    CardBody,
    Alert,
    Button, Spinner, UncontrolledTooltip
} from 'reactstrap';
import RestrictedIcon from "../SVG/RestrictedIcon";
import {getTrackerToken} from "../../utils/GetToken";
import {Link} from "react-router-dom";
import NameHeader from "./NameHeader";
import PlayerSearch from "../PlayerSearch";

export default function PlayerInfo({isValid, playerData, mapData, loading, nameHistory, goBack}) {

    const [sessionOffset, setSessionOffset] = useState(0);
    const [sessionsLoading, setSessionsLoading] = useState(false);
    const [canLoadSessions, setCanLoadSessions] = useState(true);
    const [playerSessionQuery, setPlayerSessionQuery] = useState('');
    const [selectedPlayerCompare, setSelectedPlayerCompare] = useState('');
    const [sessionData, setSessionData] = useState([]);
    
    useEffect(() => {
        if (!isValid) return;
        
        setSessionsLoading(true);
        if (selectedPlayerCompare) {
            fetchDataCompare(selectedPlayerCompare).then(() => setSessionsLoading(false));
        }
        else {
            fetchData().then(() => setSessionsLoading(false));
        }
    }, [sessionOffset, selectedPlayerCompare]);
    
    if (!isValid) {
        return (
            <Alert color="danger">
                Whoops, there appears to be no results for that player. We may not have seen this player yet.
            </Alert>
        )
    }

    if (playerData.optedOut) {
        return (
            <Card>
                <CardBody>
                    <NameHeader playerData={playerData} nameHistory={nameHistory} goBack={goBack} loading={loading} />
                    <div className="p-3 w-100 stat-table-container">
                        <div className="mt-3 d-flex justify-content-center align-items-center flex-column">
                            <RestrictedIcon />
                            <p>This player profile is <span style={{color: 'red'}}>restricted.</span></p>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }

    const mapDataCopy = mapData.slice();
    mapData.sort((a, b) => new Date(b.lastSeen) - new Date(a.lastSeen));

    const handleSessionLoadClick = () => {
        setSessionOffset(sessionOffset + 10);
    };
    
    async function fetchData() {
        const response = await fetch(`api/session/player?name=${encodeURIComponent(playerData.name)}&offset=${sessionOffset}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${getTrackerToken()}`
            }
        }).then(r => r.json());
        
        setCanLoadSessions(response.length >= 10);
        if (sessionOffset === 0) {
            setSessionData(response);
        }
        else {
            sessionData.push(...response);
        }
    }

    async function fetchDataCompare(playerB) {
        const response = await fetch(`api/session/players?playerA=${encodeURIComponent(playerData.name)}&playerB=${encodeURIComponent(playerB)}&offset=${sessionOffset}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${getTrackerToken()}`
            }
        }).then(r => r.json());
        
        setCanLoadSessions(response.length >= 10);
        if (sessionOffset > 0) {
            sessionData.push(...response);
        }
        else {
            setSessionData(response);
        }
        setSessionsLoading(false);
    }
    
    const handlePlayerSessionSelect = async (playerB) => {
        setPlayerSessionQuery(playerB.name);
        setSelectedPlayerCompare(playerB.name);
        setSessionOffset(0);
    }
    
    const removeFilter = () => {
        setPlayerSessionQuery('');
        setSelectedPlayerCompare('');
        setSessionOffset(0);
    }

    return (
        <>
        <div className='d-flex w-100 profile-flex'>
            <Card className="d-flex align-items-center justify-content-center col-lg-8 profile-stats-flex">
                <CardBody className="w-100">
                    <NameHeader playerData={playerData} nameHistory={nameHistory} goBack={goBack} loading={loading} />
                    {<HonorProgress playerData={playerData}/>}
                    {<PlayerListGroup playerData={playerData}/>}
                </CardBody>
                <CardBody className="w-100">
                    <div className="w-100 text-center mb-1 d-flex flex-column justify-content-center">
                        <h2 className="profile-sub-heading yellow gunplay mb-1">MATCH HISTORY</h2>
                        <div className="w-100 d-flex justify-content-center mb-3">
                            <div className="w-75">
                                <PlayerSearch handleSelect={handlePlayerSessionSelect} query={playerSessionQuery}
                                              setQuery={setPlayerSessionQuery} showTitle={false}
                                              placeHolder="Filter matches played with..."/>
                            </div>
                        </div>
                        {selectedPlayerCompare ?
                            <div className="d-flex justify-content-center">
                                <span>{playerData.name}'s matches with <Link to={`/player/${encodeURIComponent(selectedPlayerCompare)}`} >{selectedPlayerCompare}</Link></span>
                                <Button id='stop-compare' 
                                        onClick={removeFilter}
                                        color="danger" 
                                        size="sm" 
                                        style={{paddingTop: '0', paddingBottom: '0', paddingLeft: '0.5rem', paddingRight: '0.5rem', marginLeft: '0.3rem', textDecoration: 'none'}}>X</Button>
                                <UncontrolledTooltip target='stop-compare' placement='right'>Remove Filter</UncontrolledTooltip>
                            </div>
                            : null}
                    </div>
                    <MatchHistory sessionData={sessionData} isProfile={true} isLoading={sessionsLoading} />
                        <div className="d-flex justify-content-center mt-3">
                            <Button
                                color="primary"
                                onClick={handleSessionLoadClick}
                                disabled={!canLoadSessions}
                            >
                                {sessionsLoading ?
                                    <Spinner style={{marginRight: '0.5rem'}} className='center' color="information"
                                             size='sm'/> : null
                                }
                                {canLoadSessions ? <>Load More Matches</> : <>All Matches Loaded</>}
                            </Button>
                        </div>
                    <div className="w-100, text-center mt-3">
                        <LastSeen mapData={mapData} sessionData={sessionData}/>
                    </div>
                </CardBody>
            </Card>
            <Card className='column-gap-lg-4 profile-flex-charts justify-content-center ml-1r flex-fill'>
                <div className="d-flex justify-content-center mt-3">
                    <h2 className="profile-sub-heading yellow gunplay">MAP BREAKDOWN</h2>
                </div>
                <CardBody>
                    <div className="charts-container flex-column stat-table-container align-items-center pb-3">
                        {<TimeChart mapData={mapDataCopy} />}
                        {<KillsChart mapData={mapDataCopy}/>}
                        {<ScoreChart mapData={mapDataCopy}/>}
                    </div>
                </CardBody>
            </Card>
        </div>
        <Card
            className='mt-3 w-100'
            style={{
                minHeight: '50vh'
            }}
        >
            <CardBody className="w-100">
                {<MapStats mapData={mapData}/>}
            </CardBody>
        </Card>
        </>
    )
}