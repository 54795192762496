import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavItem, NavLink, Collapse, Nav, NavbarToggler} from 'reactstrap';
import { Link } from 'react-router-dom';
import SearchBar from './Searchbar.js';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  
  render() {
    return (
      <header>
        <Navbar className="navbar-dark navbar-expand-sm box-shadow">
          <NavbarBrand tag={Link} to="/" style={{fontWeight: 'bold'}}><span className='gunplay' style={{color: '#c3e30e', fontWeight: 'normal'}}>AAT</span>racker.net</NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} />
          <Collapse isOpen={!this.state.collapsed} navbar>
          <div className="mx-auto my-2 my-lg-0">
            <SearchBar className="mx-auto my-2 my-lg-0" />
          </div>
            <Nav navbar className="mr-auto">
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/players">Players</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/servers">Servers</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/about">About</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
