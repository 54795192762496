import React, { useState, useEffect } from 'react';
import { getTrackerToken } from '../../utils/GetToken.js';
import Loading from '../Loading.js';
import { default as ServerInfo } from './ServerInfo';
import {Container} from "reactstrap";

export default function Server(props) {

    const host = props.host;
    const [loading, setLoading] = useState(true);
    const [serverData, setServerData] = useState([]);
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const serversData = await fetch(`api/servers/${encodeURIComponent(host)}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${getTrackerToken()}`
                    }
                }).then(r => r.json());
                setServerData(serversData);
                
                if (serversData.status === null || serversData.status === undefined) {
                    setIsValid(false);
                }
                
                setLoading(false);
            }
            catch {
                setIsValid(false);
                setLoading(false);
            }
        }
        
        fetchData();
    }, [host]);

    let contents = loading ? <Loading message={`Querying ${host}...`} /> : <ServerInfo isValid={isValid} serverData={serverData} />;
    return (
        <> 
        <Container>
            {contents}
        </Container>
        </>
    );

}