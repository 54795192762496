import React from 'react';
import { Link } from 'react-router-dom';
import aaicon from '../img/aaicon.png';
import { Table } from 'reactstrap';
import InfoIcon from "./SVG/InfoIcon";
import LoadingBlur from "./LoadingBlur";

export default function MatchHistory({sessionData, isProfile, isLoading}) {
    return (
        <>
            <div className={isProfile ? "stat-table-container match-history" : "stat-table-container"}>
                <Table striped>
                    <tbody style={{filter: isLoading ? 'blur(2px)' : 'none'}}>
                    {sessionData.length ?
                        sessionData.map((session, index) => (
                            <tr key={index}>
                                <td className="session-logo"><img src={aaicon} className="session-logo" alt="AA Logo" /></td>
                                <td className="session-name">
                                    <Link to={`/session/${session.id}`} className="p-2, session-link">
                                        {session.hostName} - {session.map}
                                    </Link>
                                </td>
                                <td className="session-date">{session.date}</td>
                            </tr>
                        )) :
                        <div className="center d-flex justify-content-center align-items-center flex-column" style={{height: '25rem'}}>
                            <InfoIcon />
                            <h6>No recent matches</h6>
                        </div>
                    }
                    </tbody>
                </Table>
            </div>
        </>
    );
}