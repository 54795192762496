import React from 'react';
import ServerBlock from "./ServerBlock";
export default function TopServers({serverData}) {
    return (
        <>
            {serverData.length > 0 ?
                serverData.map((server, index) => (
                    <ServerBlock key={index} server={server} index={index} />
                )) :
                <div className="center mt-4">
                    <h5>Whoops!</h5>
                    <h6>Unable to find any online America's Army servers!</h6>
                </div>
            }
        </>
    );
}