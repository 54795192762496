import { default as Players } from './components/Players/Players';
import { default as Home } from "./components/Home/Home";
import { default as About } from "./components/About";
import { default as Privacy } from "./components/Privacy";
import { default as Player } from "./components/Player/Player";
import { default as Servers } from "./components/Servers/Servers";
import { default as Server } from "./components/Server/Server";
import { default as Map } from "./components/Map/Map";
import { default as Session } from "./components/Session/Session";
import { useParams } from "react-router-dom";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/players',
    element: <Players />
  },
  {
    path: '/player/:name',
    element: <PlayerWrapper />
  },
  {
    path: '/map/:name',
    element: <MapWrapper />
  },
  {
    path: '/servers',
    element: <Servers />
  },
  {
    path: '/server/:host',
    element: <ServerWrapper />
  },
  {
    path: '/session/:id',
    element: <SessionWrapper />
  },
  {
    path: '/about',
    element: <About />
  },
  {
    path: '/privacy',
    element: <Privacy />
  },
  {
    path: '*',
    element: <Home />
  }
];

function PlayerWrapper() {
  const { name } = useParams();
  return <Player name={name} />;
}

function ServerWrapper() {
  const { host } = useParams();
  return <Server host={host} />;
}

function MapWrapper() {
  const { name } = useParams();
  return <Map name={name} />;
}

function SessionWrapper() {
  const { id } = useParams();
  return <Session id={id} />;
}

export default AppRoutes;
