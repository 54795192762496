import React from 'react';
import { Badge, UncontrolledTooltip } from 'reactstrap';

export default function Badges({isValid, playerData}) {
    if (!isValid || !playerData) return null;
    const badges = playerData.badges;
    if(!badges) {
        if (playerData.honor >= 100 || playerData.virtualHonor >= 100) return (<><Badge className="mb-2" style={{marginRight: 5, width: '8rem'}} color="info" id="vet">Veteran</Badge><UncontrolledTooltip placement="top" target="vet">High Honor Player</UncontrolledTooltip></>);
        return (<div style={{height: '20px'}}></div>);
    }
    return(
        <>
        {badges.includes(1) ? <><Badge className="mb-2" style={{marginRight: 5, width: '8rem'}} color="danger" id="admin">Administrator</Badge><UncontrolledTooltip placement="top" target="admin">AATracker.net Administrator</UncontrolledTooltip></> : null}
        {badges.includes(2) ? <><Badge className="mb-2" style={{marginRight: 5, width: '8rem', backgroundColor: '#d94100'}} color={'none'} id="staff">Assist Staff</Badge><UncontrolledTooltip placement="top" target="staff">AAO25.com Staff Member</UncontrolledTooltip></> : null}
        {badges.includes(5) ? <><Badge className="mb-2" style={{marginRight: 5, width: '8rem', backgroundColor: '#48ab4d'}} color={'none'} id="cadmin">Community Admin</Badge><UncontrolledTooltip placement="top" target="cadmin">Community Server Admin</UncontrolledTooltip></> : null}
        {badges.includes(3) ? <><Badge className="mb-2" style={{marginRight: 5, width: '8rem'}} color="warning" id="vip">VIP</Badge><UncontrolledTooltip placement="top" target="vip">Very Important Player</UncontrolledTooltip></> : null}
        {badges.includes(4) ? <><Badge className="mb-2" style={{marginRight: 5, width: '8rem'}} color="danger" id="dev">Developer</Badge><UncontrolledTooltip placement="top" target="dev">AATracker.net Developer</UncontrolledTooltip></> : null}
        {playerData.honor >= 100  || playerData.virtualHonor >= 100 ? <><Badge className="mb-2" style={{marginRight: 5, width: '8rem'}} color="info" id="vet">Veteran</Badge><UncontrolledTooltip placement="top" target="vet">High Honor Player</UncontrolledTooltip></> : null}
        </>
    )
}