import React from 'react';
import Chart from "react-apexcharts";

export default function ScoreChart({mapData}) {
    const filteredMapData = mapData.filter(mapData => mapData.score > 0)
    filteredMapData.sort((a, b) => b.score - a.score);

    const options = {  
        chart: {
            type: "pie",
            foreColor: "#FFFFFF", 
        },
        labels: filteredMapData.map(({ map }) => (map)),
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    minAngleToShowLabel: 23
                }
            }
        }
    };

    const series = filteredMapData.map(({ score }) => (score));

    return(
        <div className="chart-wrapper mt-3">
            <h6>Score</h6>
            {filteredMapData.length
            ? <Chart options={options} series={series} type="pie" />
            : <p className="color">No data to display</p>
            }
        </div>
    )
}