import React, {useState} from 'react';
import ArrowIcon from "../SVG/ArrowIcon";
import {Link} from "react-router-dom";
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import ServerBlock from "../Home/ServerBlock";

export default function NameHeader({playerData, nameHistory, goBack, loading}) {
    const [nameHistoryLen, setNameHistoryLen] = useState(10);
    const HandleNameHistoryLoadClick = () => {
        setNameHistoryLen(nameHistoryLen + 10);    
    }
    
    return (
        <div className="d-flex col-lg-8 w-100 pbb">
            <a className="backbtn mt-1" onClick={goBack}>
                <ArrowIcon isProfile={true} className="d-flex align-self-start back-profile"
                           style={{flex: '1', flexBasis: '0'}}/>
            </a>
            <div className="d-flex align-items-center justify-content-center"
                 style={{flex: '1', flexBasis: '0'}}>
                {playerData.onlineServer ?
                    <>
                        <Link to={`/server/${playerData.onlineServer.address}`}>
                            <span id="status" className="online-circle"/>
                        </Link>
                        <UncontrolledTooltip placement="bottom" target="status" style={{
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            maxWidth: '32em',
                            width: '32em',
                            textAlign: 'left'
                        }}>
                            <ServerBlock key='online' server={playerData.onlineServer} index='online'
                                         playerName={playerData.name}/>
                        </UncontrolledTooltip>
                    </>
                    : null}
                <h1 className="player-name" style={{marginLeft: '5px'}}>
                    {!loading ? playerData.name.replace('~', '-') ?? "Player Not Found" : null}
                </h1>
                {nameHistory?.length ?
                    <UncontrolledDropdown direction="down" className="align-self-start">
                        <DropdownToggle
                            caret
                            className="name-history"
                            tag="a"
                        >
                        </DropdownToggle>
                        <DropdownMenu className="name-history-dropdown dd-name">
                            <DropdownItem header className="name-history-header">Player Name
                                History</DropdownItem>
                            <DropdownItem divider/>
                            {nameHistory.slice(0, nameHistoryLen).map((name, index) => (
                                <DropdownItem text key={index} className="name-history-item">
                                    {name}
                                </DropdownItem>
                            ))}
                            {nameHistory?.length > nameHistoryLen ?
                                <>
                                    <div className="d-flex justify-content-center">
                                        <Button color="link" onClick={HandleNameHistoryLoadClick}>Load More</Button>
                                    </div>
                                </>
                            : null} 
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    : null}
            </div>
        </div>
    )
}