import React from 'react';
import { Badge, UncontrolledTooltip } from 'reactstrap';

export default function Badges({player, index}) {
    const badges = player.badges;
    index = index.toString();
    if(!badges) {
        if (player.honor >= 100 || player.virtualHonor >= 100) return(
            <Badge color="info" id={'vet' + index}>    
            <span>
                V
            </span>
            <UncontrolledTooltip
            placement="top"
            target={'vet' + index}
            >
                AA Veteran
            </UncontrolledTooltip>
        </Badge>
        )
        return null;
    };
    if (badges.includes(1)) return(
        <Badge color="danger" id={'admin' + index}>    
            <span>
                A
            </span>
            <UncontrolledTooltip
            placement="top"
            target={'admin' + index}
            >
                Administrator
            </UncontrolledTooltip>
        </Badge>)
    if (badges.includes(2)) return(
        <Badge color="none" style={{backgroundColor: '#d94100'}} id={'staff' + index}>    
            <span>
                S
            </span>
            <UncontrolledTooltip
            placement="top"
            target={'staff' + index}
            >
                Assist Staff
            </UncontrolledTooltip>
        </Badge>
    )
    if (badges.includes(5)) return(
        <Badge color="none" style={{backgroundColor: '#48ab4d'}} id={'ca' + index}>    
            <span>
                C
            </span>
            <UncontrolledTooltip
            placement="top"
            target={'ca' + index}
            >
                Community Admin
            </UncontrolledTooltip>
        </Badge>
    )
    if (badges.includes(3)) return(
        <Badge color="warning" id={'vip' + index}>    
            <span>
                V
            </span>
            <UncontrolledTooltip
            placement="top"
            target={'vip' + index}
            >
                VIP
            </UncontrolledTooltip>
        </Badge>
    )
    if (badges.includes(4)) return(
        <Badge color="danger" id={'dev' + index}>    
            <span>
                D
            </span>
            <UncontrolledTooltip
            placement="top"
            target={'dev' + index}
            >
                Developer
            </UncontrolledTooltip>
        </Badge>
    )
    return null;
}