import React from 'react';
import Chart from "react-apexcharts";

export default function TimeChart({mapData}) {
    const filteredMapData = mapData.filter(mapData => mapData.minutesPlayed > 0)
    filteredMapData.sort((a, b) => b.minutesPlayed - a.minutesPlayed);

    const options = {  
        chart: {
            foreColor: "#FFFFFF"
        },
        labels: filteredMapData.map(({ map }) => (map)),
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                customScale: 1.0,
                dataLabels: {
                    minAngleToShowLabel: 23,
                }
            }
        },
        tooltip: {
            y: {
              formatter: function(value) {
                const hours = Math.floor(value / 60);
                const minutes = value % 60;
                return `${hours} ${hours > 1 || hours === 0 ? 'hours' : 'hour'} ${minutes} ${minutes > 1 || minutes === 0 ? 'minutes' : 'minute'}`;
              }
            }
          }
    };

    const series = filteredMapData.map(({ minutesPlayed}) => (minutesPlayed));

    return(
        <>
        <div className="chart-wrapper mt-3">
            <h6>Time Played</h6>
            {filteredMapData.length 
                ? <Chart options={options} series={series} type="pie" /> 
                : 
                <p className="color">No data to display</p>
            }
        </div>
        </>
    )
}
