import React from 'react';
import {Spinner} from 'reactstrap';

export default function LoadingBlur({message}) {
    return (
        <div className="overlay-content">
            <Spinner animation="border" variant="primary" className="yellow" />
            <p>{message}</p>
        </div>
    );
};
