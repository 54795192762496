import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowIcon from '../SVG/ArrowIcon.js';
import Loading from '../Loading.js';
import { getTrackerToken } from '../../utils/GetToken.js';
import { default as MapTable } from './MapTable';
import {Container} from "reactstrap";

export default function Map(props) {
    const [loading, setLoading] = useState(true);
    const [isValid, setValid] = useState(true);
    const [mapData, setMapData] = useState([]);
    const name = props.name;
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                const statsData = await fetch(`api/map/${encodeURIComponent(name)}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${getTrackerToken()}`
                    }
                }).then(r => r.json());

                if (statsData.length === 0) {
                    setValid(false);
                }
                else {
                    setMapData(statsData);
                    setValid(true);
                }
            }
            catch {
                setValid(false);
            }
            
            setLoading(false);
        }
        
        fetchData();
    }, [name]);

    const goBack = () => {
        navigate(-1);
    };

    let totalKills = mapData.reduce((sum, player) => sum + player.kills, 0);
    let totalScore = mapData.reduce((sum, player) => sum + player.score, 0);
    let contents = loading ? <Loading message={`Loading data for ${name}`}/> : <MapTable players={mapData} isValid={isValid} />;
    
    return (
        <>
        <Container>
            <div>
                <div className="d-flex align-items-center">
                    <a className="backbtn" onClick={goBack}>
                        <ArrowIcon/>
                    </a>
                    <h1 className="page-header yellow gunplay mt-2" style={{marginLeft: '10px'}}>{isValid ? name : "MAP NOT FOUND"}</h1>
                </div>
                {isValid ?
                    (<p style={{color: 'white', fontSize: '1em'}}>Tracking <span className="yellow gunplay">{mapData.length}</span> Players with <span className="yellow gunplay">{totalKills}</span> Kills and <span className="yellow gunplay">{totalScore}</span> Score on {name}</p>) : null}
            </div>
            {contents}
        </Container>
        </>
    );

}