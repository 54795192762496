import React, { useState, useEffect } from 'react';
import { getTrackerToken } from '../../utils/GetToken.js';
import Loading from '../Loading.js';
import { default as SessionInfo } from './SessionInfo';
import profilebanner from '../../img/profilelogo.png';
import profilebanner2 from '../../img/profilelogo2.png';
import profilebanner3 from '../../img/profilelogo3.png';
import profilebanner4 from '../../img/profilelogo4.png';
import {Container} from "reactstrap";

export default function Session(props) {

    const id = props.id;
    const [loading, setLoading] = useState(true);
    const [sessionData, setSessionData] = useState([]);
    const [isValid, setIsValid] = useState(true);
    const [bannerId, setBannerId] = useState(4);
    const [bannerStyle, setBannerStyle] = useState({paddingTop: '4rem', background: `url(${profilebanner4}) no-repeat center -20px`});

    useEffect(() => {
        switch (bannerId) {
            case 1:
                setBannerStyle({paddingTop: '8rem', background: `url(${profilebanner}) no-repeat center -20px`});
                break;
            case 2:
                setBannerStyle({paddingTop: '8rem', background: `url(${profilebanner2}) no-repeat center -20px`});
                break;
            case 3:
                setBannerStyle({paddingTop: '8rem', background: `url(${profilebanner3}) no-repeat center -20px`});
                break;
            case 4:
                setBannerStyle({paddingTop: '8rem', background: `url(${profilebanner4}) no-repeat center -20px`});
                break;
            default:
                break;
        }
    }, [bannerId])

    useEffect(() => {
        async function fetchData() {
            try {
                const sessionsData = await fetch(`api/session/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${getTrackerToken()}`
                    }
                }).then(r => r.json());
                
                setSessionData(sessionsData);
            }
            catch (ex) {
                setIsValid(false);
            }
            
            setLoading(false);
        }
        
        setBannerId(Math.round(Math.random() * 3) + 1);
        fetchData();
    }, [id]);

    let contents = loading ? <Loading message={`Loading Match...`} /> : <SessionInfo isValid={isValid} sessionData={sessionData} />;
    return (
        <>
        <Container>
            <div style={bannerStyle}>
                {contents}
            </div>
        </Container>
        </>
    );

}