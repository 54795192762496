import {getTrackerToken} from './GetToken.js';

export const fetchData = async () => {
    try {
        return await fetch('api/players', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${getTrackerToken()}`
            }
        }).then(r => r.json());
    }
    catch(ex) {
        console.error(ex);
    }
}