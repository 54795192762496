import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Table, Alert } from 'reactstrap';
import { default as sortPlayers } from '../Players/SortPlayers';
import PaginationControl from "../Pagination/PaginationControl";

export default function MapTable({players, isValid}) {
    const [sortDirection, setSortDirection] = useState(-1);
    const [sortColumn, setSortColumn] = useState("kills");
    const [currentPage, setCurrentPage] = useState(1);
    const playersPerPage = 25;

    const sortedPlayers = sortPlayers(players, sortDirection, sortColumn);

    const indexOfLastPlayer = currentPage * playersPerPage;
    const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
    const currentPlayers = sortedPlayers.slice(indexOfFirstPlayer, indexOfLastPlayer);
    const totalPages = Math.ceil(sortedPlayers.length / playersPerPage);

    if (!isValid) {
        return (
            <Alert color="danger">
                Whoops, there appears to be no results for that map. We may not have tracked anyone playing it yet, or it may not exist.
            </Alert>
        )
    }

    const handleSort = (column) => {
        if (column === sortColumn) {
            setSortDirection(-sortDirection);
        }
        else {
            setSortColumn(column);
            setSortDirection(-1);
        }
    }

    const handlePageChange = (pageNumber, totalPages) => {
        if (pageNumber > totalPages || pageNumber < 1) return;
        setCurrentPage(pageNumber);
    }

    return (
        <>
        <div className="table-wrapper">
            <Table striped>
                <thead>
                    <tr>
                        <th className="sortable" onClick={() => handleSort("name")}>
                            Name {sortColumn === "name" ? (sortDirection === 1 ? "▲" : "▼") : ""}
                        </th>
                        <th className="sortable" onClick={() => handleSort("kills")}>
                            Kills {sortColumn === "kills" ? (sortDirection === 1 ? "▲" : "▼") : ""}
                        </th>
                        <th className="sortable" onClick={() => handleSort("deaths")}>
                            Deaths {sortColumn === "deaths" ? (sortDirection === 1 ? "▲" : "▼") : ""}
                        </th>
                        <th className="sortable" onClick={() => handleSort("kdr")}>
                            KDR {sortColumn === "kdr" ? (sortDirection === 1 ? "▲" : "▼") : ""}
                        </th>
                        <th className="sortable" onClick={() => handleSort("score")}>
                            Score {sortColumn === "score" ? (sortDirection === 1 ? "▲" : "▼") : ""}
                        </th>
                        <th className="sortable" onClick={() => handleSort("time")}>
                            Time Played {sortColumn === "time" ? (sortDirection === 1 ? "▲" : "▼") : ""}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentPlayers.map(player => (
                        <tr key={player.name}>
                            <td><Link to={`/player/${encodeURIComponent(player.name)}`} >{player.name}</Link></td>
                            <td>{player.kills}</td>
                            <td>{player.deaths}</td>
                            <td>{(player.deaths !== 0 ? player.kills / player.deaths : player.kills).toFixed(2)}</td>
                            <td>{player.score}</td>
                            <td>{`${Math.floor(player.minutesPlayed / 60).toString().padStart(2, '0')}h${(player.minutesPlayed % 60).toString().padStart(2, '0')}m`}</td>
                        </tr>
                    ))}
                    {currentPlayers.length < playersPerPage && (
                        [...Array(playersPerPage - currentPlayers.length)].map((_, index) => (
                            <tr key={`empty-row-${index}`}>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                        ))
                    )}
                </tbody>   
            </Table>
        </div>
            
        <PaginationControl currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
        </>
    );
}