import React from 'react';
import {Card, CardBody, Container} from 'reactstrap';

export default function Privacy() {
  return (
      <Container>
          <div style={{marginBottom: '2rem'}}>
            <h1 className="yellow gunplay page-header">PRIVACY POLICY</h1>
            <Card color="dark" style={{overflow: 'hidden'}}>
              <CardBody>
                <p className="mb-0">
                  The only data that AATracker.net directly collects is IP Addresses included with a submission of a server to be tracked in order to prevent abuse. This information is held indefinitely. If you do not submit a server to be tracked, none of your information will be collected by AATracker.net.
                </p>
                <p></p>
                <p>
                    AATracker.net makes use of several external services to provide functionality for the website. You may view their privacy policies below:
                    <ul>
                        <li>HCaptcha - <a href="https://www.hcaptcha.com/privacy" target="_blank" rel="noopener noreferrer">https://www.hcaptcha.com/privacy</a></li>
                        <li>Cloudflare - <a href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noopener noreferrer">https://www.cloudflare.com/privacypolicy/</a></li>
                    </ul>
                </p>
                <p></p>
                <p>You may contact us via our <a href="https://discord.gg/6RdwmbJvp6">Discord</a> or by email <a href="mailto:admin@aatracker.net">admin@aatracker.net</a></p>
                <p></p>
              </CardBody>
            </Card>
          </div>
      </Container>
  );
}
