import React from 'react';
import { Link } from 'react-router-dom';

export default function LastSeen({mapData, sessionData}) {
    if (sessionData.length > 0 && sessionData[0]) {
        return (
            <div className='ellipsis-overflow-block'>
                Last Tracked: <Link style={{fontSize: '0.95em'}} to={`/session/${sessionData[0].id}`}>{sessionData[0].hostName} - {sessionData[0].map}</Link> - <span className="date">({sessionData[0].date})</span>
            </div>
        );
    }
    else {
        return (
            <div className='ellipsis-overflow-block'>
                Last Tracked: <span style={{fontSize: '0.95em'}}>{mapData[0].lastSeenServer} - {mapData[0].map}</span> - <span className="date">({mapData[0].lastSeen})</span>
            </div>
        );
    }
    
}