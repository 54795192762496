import React from 'react';
import { default as getKDR } from './GetKDR';
import {
    Card,
    CardBody,
    CardTitle,
    CardText
} from 'reactstrap';
import KillsIcon from "../SVG/KillsIcon";
import DeathsIcon from "../SVG/DeathsIcon";
import TimeIcon from "../SVG/TimeIcon";
import CalcIcon from "../SVG/CalcIcon";
import ScoreIcon from "../SVG/ScoreIcon";
import BarIcon from "../SVG/BarIcon";
import HonorIcon from "../SVG/HonorIcon";

export default function PlayerListGroup({playerData}) {
    const hours = Math.floor(playerData.minutesPlayed / 60);
    const minutes = playerData.minutesPlayed % 60;
    const kdr = getKDR(playerData.kills, playerData.deaths);
    return (
        <>
        <div className="stat-table-container">
            <div className='stat-container'>
                <Card className="stat-block">
                    <CardBody>
                        <CardTitle>
                            <KillsIcon />
                            <h5 className="mt-2 gunplay yellow">KILLS</h5>
                        </CardTitle>
                        <CardText>
                            <span className="stat-value">{playerData.kills}</span>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="stat-block">
                    <CardBody>
                        <CardTitle>
                            <DeathsIcon />
                            <h5 className="mt-2 gunplay yellow">DEATHS</h5>
                        </CardTitle>
                        <CardText>
                            <span className="stat-value">{playerData.deaths}</span>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="stat-block">
                    <CardBody>
                        <CardTitle>
                            <CalcIcon />
                            <h5 className="mt-2 gunplay yellow">KDR</h5>
                        </CardTitle>
                        <CardText>
                        <span className="stat-value">
                            {
                                kdr < 0.75 ? (
                                    <span style={{color: '#ff0000'}}>{kdr}</span>
                                ) : kdr < 1.0 ? (
                                    <span style={{color: '#ff6a00'}}>{kdr}</span>
                                ) : kdr < 1.75 ? (
                                    <span style={{color: '#fff700'}}>{kdr}</span>
                                ) : kdr < 2.00 ? (
                                    <span style={{color: '#91ff00'}}>{kdr}</span>
                                ) : (
                                    <span style={{color: '#13bf00'}}>{kdr}</span>
                                )
                            }
                        </span>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="stat-block">
                    <CardBody>
                        <CardTitle>
                            <TimeIcon />
                            <h5 className="mt-2 gunplay yellow">TIME PLAYED</h5>
                        </CardTitle>
                        <CardText>
                            <span className="stat-value">{`${hours} ${hours > 1 || hours === 0 ? 'hours' : 'hour'} ${minutes} ${minutes > 1 || minutes === 0 ? 'minutes' : 'minute'}`}</span>
                        </CardText>
                    </CardBody>
                </Card>
            </div>
            <div className='stat-container'>
                <Card className="stat-block">
                    <CardBody>
                        <CardTitle>
                            <ScoreIcon />
                            <h5 className="mt-2 gunplay yellow">SCORE</h5>
                        </CardTitle>
                        <CardText>
                            <span className="stat-value">{playerData.score}</span>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="stat-block">
                    <CardBody>
                        <CardTitle>
                            <BarIcon />
                            <h5 className="mt-2 gunplay yellow">SCORE NEEDED</h5>
                        </CardTitle>
                        <CardText>
                            <span className="stat-value">{playerData.scoreNeeded - playerData.scoreRemainder}</span>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="stat-block">
                    <CardBody>
                        <CardTitle>
                            <HonorIcon />
                            <h5 className="mt-2 gunplay yellow">HONOR</h5>
                        </CardTitle>
                        <CardText>
                            <span className="stat-value">{playerData.honor}</span>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="stat-block">
                    <CardBody>
                        <CardTitle>
                            <HonorIcon />
                            <h5 className="mt-2 gunplay yellow">VIRTUAL HONOR</h5>
                        </CardTitle>
                        <CardText>
                            <span className="stat-value">{playerData.virtualHonor}</span>
                        </CardText>
                    </CardBody>
                </Card>
            </div>
            {playerData.trackedSince ?
                <div className='center p-3'>
                    <span className="date">
                        Tracking <span style={{fontWeight: 'bolder'}}>{playerData.name}</span> since {playerData.trackedSince}
                    </span>
                </div> 
            : null}
        </div>
        </>
    )
}