import React from 'react';
import MailIcon from "./SVG/MailIcon";

const Footer = () => {  
    return (
        <footer className="bg-dark text-center flex-shrink-0 pt-4">
            <p className="text-white footer-text">
                <a style={{float: 'left', paddingLeft: '2rem', color: '#E2E2E2', fontSize: '0.8em', textDecoration: 'none'}} href="mailto:admin@aatracker.net">
                <MailIcon /> admin@aatracker.net
                </a>
                <span style={{fontSize: '0.75rem'}}>&copy; {new Date().getFullYear()} AATracker.net</span>
                <a style={{
                    float: 'right',
                    paddingRight: '2rem',
                    color: '#E2E2E2',
                    fontSize: '0.8em',
                    textDecoration: 'none'
                }} href="/Privacy">Privacy</a>
            </p>
        </footer>
    );
};

export default Footer;