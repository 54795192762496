import React, { Component, createContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { fetchData } from './utils/FetchPlayers';

export const PlayerDataContext = createContext();

export default class App extends Component {
  static displayName = App.name;

  state = {
    data: null
  };

  componentDidMount() {
    fetchData().then(data => this.setState({ data }));
  }

  render() {
    return (
      <PlayerDataContext.Provider value={this.state.data}>
        <Layout>
          <Routes>
            {AppRoutes.map((route, index) => {
              const { element, ...rest } = route;
              return <Route key={index} {...rest} element={element} />;
            })}
          </Routes>
        </Layout>
      </PlayerDataContext.Provider>
    );
  }
}
