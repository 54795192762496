import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import ArrowIcon from '../SVG/ArrowIcon.js';
import { default as Badges } from './Badges';
import Flags from 'country-flag-icons/react/3x2'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Alert,
    CardText,
    Table,
    UncontrolledTooltip, Button, Spinner
} from 'reactstrap';
import MatchHistory from "../MatchHistory";
import MapIcon from "../SVG/MapIcon";
import PlayersIcon from "../SVG/PlayersIcon";
import RoundIcon from "../SVG/RoundIcon";
import ServerIcon from "../SVG/ServerIcon";
import {getTrackerToken} from "../../utils/GetToken";

export default function ServerInfo({isValid, serverData}) {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    const [sessionOffset, setSessionOffset] = useState(0);
    const [sessionsLoading, setSessionsLoading] = useState(false);
    const [canLoadSessions, setCanLoadSessions] = useState(true);

    useEffect(() => {
        if (!isValid) return;
        if (serverData.sessionData.length < 10) setCanLoadSessions(false);

        async function fetchData() {
            const response = await fetch(`api/session/server?host=${serverData.address}&offset=${sessionOffset}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${getTrackerToken()}`
                }
            }).then(r => r.json());

            if (response.length < 10) setCanLoadSessions(false);
            serverData.sessionData.push(...response);
        }

        if (sessionOffset !== 0) {
            setSessionsLoading(true);
            fetchData().then(() => setSessionsLoading(false));
        }
    }, [sessionOffset]);

    const handleSessionLoadClick = () => {
        setSessionOffset(sessionOffset + 10);
    };

    if (!isValid) {
        return (
            <>
                <div className="d-flex align-items-center">
                    <a className="backbtn" onClick={goBack}>
                        <ArrowIcon/>
                    </a>
                    <h1 className="page-header yellow gunplay mt-2" style={{marginLeft: '10px'}}>SERVER NOT FOUND</h1>
                </div>
                <Alert color="danger" className="mt-1">
                    Whoops, there appears to be no results for that server. Is it offline or not added to tracker?.
            </Alert>
            </>
        )
    };

    const Flag = Flags[serverData.country];

    return (
        <>
        <Badges isValid={isValid} serverData={serverData} />
        <Card
            className="my-2"
            color="dark"
            inverse
            style={{
                width: '100%',
                minHeight: '50vh'
            }}>
            <CardBody>
                <div className="d-flex w-100">
                    <a className="backbtn mt-1" onClick={goBack}>
                        <ArrowIcon className="d-flex align-self-start"
                                   style={{flex: '1', flexBasis: '0'}}/>
                    </a>
                    <div className="d-flex align-items-center justify-content-center"
                         style={{flex: '1', flexBasis: '0'}}>
                        <div className="d-flex flex-column">
                            <h1 className="page-header yellow gunplay">{serverData.status.hostname.toUpperCase()}</h1>
                            <div className="d-flex align-items-center justify-content-center">
                                {Flag && (
                                    <>
                                        <UncontrolledTooltip placement="left" target="country">
                                            {serverData.country}
                                        </UncontrolledTooltip>
                                        <Flag
                                            id="country"
                                            style={{
                                                width: '1.2rem',
                                                marginRight: '0.5rem'
                                            }} />
                                    </>
                                )}
                                {serverData.address}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="stat-table-container mt-3">
                    <div className='stat-container'>
                    <Card className="stat-block">
                            <CardBody>
                                <CardTitle>
                                    <MapIcon/>
                                    <h5 className="mt-2 yellow gunplay">MAP</h5>
                                </CardTitle>
                                <CardText>
                                    <Link className="stat-value-link"
                                          to={`/map/${encodeURIComponent(serverData.status.mapname)}`}>{serverData.status.mapname}</Link>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card className="stat-block">
                            <CardBody>
                                <CardTitle>
                                    <PlayersIcon/>
                                    <h5 className="mt-2 yellow gunplay">PLAYERS</h5>
                                </CardTitle>
                                <CardText>
                                    <span
                                        className="stat-value">{serverData.status.numplayers}/{serverData.status.maxplayers}</span>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card className="stat-block">
                            <CardBody>
                                <CardTitle>
                                    <RoundIcon/>
                                    <h5 className="mt-2 yellow gunplay">ROUND</h5>
                                </CardTitle>
                                <CardText>
                                    <span className="stat-value">{serverData.status.current_round}</span>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card className="stat-block">
                            <CardBody>
                                <CardTitle>
                                    <ServerIcon/>
                                    <h5 className="mt-2 yellow gunplay">STATUS</h5>
                                </CardTitle>
                                <CardText style={{textShadow: 'none'}}>
                                    <span className="stat-value"><span
                                        style={{color: 'green', fontWeight: 'bolder'}}>ONLINE</span></span>
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-3 mb-3">
                    <h2 className="profile-sub-heading yellow gunplay">PLAYERS</h2>
                </div>
                <div className="table-wrapper">
                    <Table striped>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Kills</th>
                            <th>Deaths</th>
                            <th>Goal</th>
                            <th>Leader</th>
                            <th>ROE</th>
                            <th>Ping</th>
                        </tr>
                        </thead>
                        <tbody>
                        {serverData.players.length === 0 ?
                            <td colSpan="7" style={{textAlign: 'center'}}>
                                <h6>No Players Online</h6>
                            </td>
                            : serverData.players.sort((a, b) => b.enemy - a.enemy).map(player => (
                                <tr key={player.player}>
                                    <td><Link
                                        to={`/player/${encodeURIComponent(player.player)}`}>{player.player}</Link></td>
                                    <td>{player.enemy}</td>
                                    <td>{player.kia}</td>
                                    <td>{player.goal}</td>
                                    <td>{player.leader}</td>
                                    <td>{player.roe}</td>
                                    <td>{player.ping}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="d-flex justify-content-center mt-3">
                    <h2 className="profile-sub-heading yellow gunplay">RECENT MATCHES</h2>
                </div>
                <MatchHistory sessionData={serverData.sessionData}/>
                {serverData.sessionData.length >= 10 ?
                    <div className="d-flex justify-content-center mt-3">
                        <Button
                            color="primary"
                            onClick={handleSessionLoadClick}
                            disabled={!canLoadSessions}
                        >
                            {sessionsLoading ?
                                <Spinner style={{marginRight: '0.5rem'}} className='center' color="information"
                                         size='sm'/> : null
                            }
                            {canLoadSessions ? <>Load More Matches</> : <>All Matches Loaded</>}
                        </Button>
                    </div> : null}
            </CardBody>
        </Card>
        </>
    )
}