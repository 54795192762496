import React from 'react';
import { Link } from 'react-router-dom';
import {Card, CardBody, CardImg, CardImgOverlay, CardTitle, Container} from 'reactstrap';
import trackerimg from '../img/trackerapp.png';
import requeryimg from '../img/requery.png';

export default function About() {
  return (
    <Container>
      <div style={{marginBottom: '2rem'}}>
        <h1 className="yellow gunplay page-header">ABOUT</h1>
        <Card color="dark" style={{overflow: 'hidden'}}>
          <CardBody>
            <div>
              <Card inverse>
                <CardImg
                  alt="Card image cap"
                  src={trackerimg}
  
                  width="75%"
                />
                <CardImgOverlay>
                  <CardTitle tag="h5" style={{fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    AATracker Service
                  </CardTitle>
                </CardImgOverlay>
              </Card>
            </div>
            <h4 className="about yellow gunplay">WHAT IS AATRACKER?</h4>
            <p className="mb-0">
              AATracker is a set of applications which track America's Army 2.5 Assist player stats across servers, maps and game sessions, consolidates the data and presents it to you in an easy-to-read format. 
            </p>
            <p></p>
            <p className="mb-0">
              Much like the original implementation of BattleTracker, The AATracker Service tracks these stats by querying a collection of servers using the Gamespy V2 protocol at a regular interval.
              Unlike BattleTracker, we do not have access to a masterlister of servers and as such we require servers to be manually added. 
              Servers can be added by visiting the <Link to="/servers">Servers</Link> page and selecting the 'Add Server' button. Alternatively, servers may be added using the /addserver command on our Discord.
            </p>
            <p></p>
            AATracker calculates the honor of each player, using their in-game honor as a starting point and tracked score to determine the players progress towards the next honor level. Once per day, Assist in-game honor is synced 
            using this information.
            <p></p>
            <h4 className="about yellow gunplay">HOW DOES IT WORK?</h4>
            <p className="mb-0">
              Each player has stats for each individual map, including kills, deaths, score, the last time they played said map and the last server which they played it on. 
              The AATracker Service requests player information from each tracked game server at a regular interval and keeps track of this information. Should the map change, set end or server crash 
              the stats for all connected players will be updated. In addition to this, should a player disconnect (quit, crash, or be kicked) their stats will also be saved. 
              The AATracker Service even works to detect quick reconnects, and saves the stats the player had prior to the quick reconnect in order for the stats to remain as accurate as possible.
            </p>
            <p></p>
            <h4 className="about yellow gunplay">ACCURACY</h4>
            <p className="mb-0">
              AATracker.net works the nearly the same as BattleTracker used to, and due to less servers being online, we are able to query more frequently. As was the case with BattleTracker, it is possible that a kill 
              may be missed should it occur just prior to the end of a round or map change. This isn't a super common occurance and stats will be very accurate the vast majority of the time.
            </p>
            <p></p>
            <h4 className="about yellow gunplay">WHAT IF A SERVER GOES OFFLINE?</h4>
            <p className="mb-0">
              The AATracker Service removes servers which do not respond to several queries from it's list of servers to query at regular intervals, periodically requerying them to see if they have come back online.
            </p>
            <p></p>
            <img style={{display: 'block', margin: '0 auto'}} src={requeryimg} alt="Server Requery" />
            <p></p>
            <h4 className="about yellow gunplay">WHAT IS VIRTUAL HONOR?</h4>
            <p className="mb-0">
              Virtual Honor represents a players progress towards the next honor level. Should they obtain a new honor level, their virtual honor will increase prior to being synced with Assist.
              Virtual Honor is calculated using America's Army 2 Honor formula, and has been tested to ensure accuracy.
            </p>
            <p></p>
            <h4 className="about yellow gunplay">ARE CHEAT SERVERS TRACKED?</h4>
            <p className="mb-0">
              No, cheat servers are not tracked. In addition to it not being fair, America's Army servers running cheat mode enabled or running the SF or Shotgun mods do not report player stats to begin with, so we would be unable to track these stats even if we wanted to.
            </p>
            <p></p>
            <h4 className="about yellow gunplay">WHY IS MY SERVER NOT TRACKED?</h4>
            <p className="mb-0">
              Your server likely needs to be added to our database of servers to track. Currently this can be done through our Discord using the /addserver command, or via the servers page by selecting the 'Add Server' button.
              The AATracker Service queries our database, roughly every two minutes, to check for new servers which have been added. Once a new server is found, it will begin to be queried to determine if the player count is above the minimum threshold to track stats.
            </p>
            <p></p>
            <h4 className="about yellow gunplay">VETERAN, VIP, ADMIN BADGES??</h4>
            <p className="mb-0">
              The Veteran badge is automatically displayed for players who have greater than 100 honor, or who have gained greater than 100 virtual honor. It is a way for us to recognize the dedication and commitment it takes for a player to reach this achievement.
              The VIP badge is simply a badge given to those who regularly play with us in game, have made outstanding contributions to the America's Army community as a whole, and who have had nothing but a positive attitude.
  
              The Community Admin badge is awarded to players who help manage our game server and/or Discord server.
            </p>
            <p></p>
            <h4 className="about yellow gunplay">CONTACT INFORMATION</h4>
            <p className="mb-0">
              Please direct any inquiries to our <a href="https://discord.gg/6RdwmbJvp6">Discord</a> or reach out by email at <a href="mailto:admin@aatracker.net">admin@aatracker.net</a>
            </p>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
}
