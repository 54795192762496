import React from 'react';
import { Link } from 'react-router-dom';
import { Table, UncontrolledTooltip } from 'reactstrap';
import AddServerModal from './AddServerModal.js';

export default function ServersTable({servers, offlineServers}) {
    return (
        <>
        <AddServerModal />
        <div className="table-wrapper">
            <Table striped>
                <thead>
                    <tr>
                        <th style={{textAlign: 'center'}}>Country</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Map</th>
                        <th>Players</th>
                        <th>Round</th>
                        <th>Host</th>
                    </tr>
                </thead>
                <tbody>
                {servers.sort((a, b) => {
                    const playerComparison = b.status.numplayers - a.status.numplayers;
                    // If the number of players is the same, compare by map name
                    const mapComparison = a.status.mapname.localeCompare(b.status.mapname);
                    const serverNameComparison = a.status.hostname.localeCompare(b.status.hostname);
                    return playerComparison !== 0
                        ? playerComparison
                        : mapComparison !== 0
                            ? mapComparison
                            : serverNameComparison;
                }).map((server, index) => (
                    <tr key={server.address}>
                        <td style={{textAlign: 'center'}}>
                            {server.countryComponent ?
                                <>
                                    <UncontrolledTooltip
                                        placement="left"
                                        target={'server' + index}
                                    >
                                        {server.country}
                                    </UncontrolledTooltip>
                                    <server.countryComponent style={{width: '1rem'}} id={'server' + index}/>
                                </>
                                : null}
                        </td>
                        <td><Link to={`/server/${server.address}`} >{server.status.hostname}</Link></td>
                        <td style={{color: 'green'}}>ONLINE</td>
                        <td><Link to={`/map/${encodeURIComponent(server.status.mapname)}`}>{server.status.mapname}</Link></td>
                        <td style={server.status.numplayers >= 1 ? {color: "green"} : {}}>{server.status.numplayers}/{server.status.maxplayers}</td>
                        <td>{server.status.current_round}</td>
                        <td>{server.address}</td>
                    </tr>
                ))}
                {offlineServers.map(server => (
                    <tr key={server.address}>
                        <td></td>
                        <td>{server.name === null ? "" : server.name}</td>
                        <td style={{color: 'red'}}>OFFLINE</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{server.address}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
        </>
    )
}