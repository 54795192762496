import React from 'react';
import { Link } from 'react-router-dom';
import {Card, CardBody, CardText, CardTitle, UncontrolledTooltip} from "reactstrap";
import PlayersIconSmall from "../SVG/PlayersIconSmall";
import MapIconSmall from "../SVG/MapIconSmall";
import RoundIconSmall from "../SVG/RoundIconSmall";
import ServerIconSmall from "../SVG/ServerIconSmall";

export default function ServerBlock({server, index, playerName = null}) {
    let ImageComponent;
    try {
        const imgName = server.status.mapname
            .replace(/ /g, '_')
            .toLowerCase()
            .replace('[maap]', 'mp')
            .replace('[AA3]', 'aa3');
        ImageComponent = require(`../../img/map/${imgName}.png`);
    }
    catch (e) {
       ImageComponent = require('../../img/map/pipeline_sf.png');
    }

    return (
        <>
            <div className='server-block mt-3 ml-02' style={{border: playerName ? '1px solid #444' : 'none'}}>
                <div className='block-wrapper'>
                    <div className="title-image">
                        <Link to={`/map/${encodeURIComponent(server.status.mapname)}`}>
                            <img src={ImageComponent} alt="Map" className='p-2' style={{borderRadius: '15px'}}/>
                        </Link>
                        <Link to={`/server/${server.address}`} className="session-link ellipsis-overflow-block"
                              style={{fontSize: '0.95em', marginTop: '0.5rem'}}>{server.name}</Link>
                    </div>
                    <span id={`online-status-${index}`} className="online-circle"
                          style={{float: 'right', margin: '0.5rem'}}/>
                    <UncontrolledTooltip placement="top" target={`online-status-${index}`}>
                        {server.name} is online!
                    </UncontrolledTooltip>
                </div>
                <div className="stat-table-container" style={{marginTop: '0.3rem', boxShadow: 'none'}}>
                    <div className='stat-container-flex'>
                        <Card className="stat-block-sm" >
                            <CardBody>
                                <CardTitle id={`map-${index}`}>
                                    <MapIconSmall/>
                                    <UncontrolledTooltip placement="top" target={`map-${index}`}>Map
                                        Name</UncontrolledTooltip>
                                </CardTitle>
                                <CardText className='overflow'>
                                    <Link className="link-no-shadow"
                                          to={`/map/${encodeURIComponent(server.status.mapname)}`}>{server.status.mapname}</Link>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card className="stat-block-sm">
                            <CardBody>
                                <CardTitle id={`players-${index}`}>
                                    <PlayersIconSmall/>
                                    <UncontrolledTooltip placement="top" target={`players-${index}`}>Player
                                        Count</UncontrolledTooltip>
                                </CardTitle>
                                <CardText>
                                    <span className="stat-value glow">
                                        {server.status.numplayers > 0 ? (
                                            <>
                                                <span
                                                    style={{color: 'green', fontWeight: 'bolder'}}>
                                                    {server.status.numplayers} / {server.status.maxplayers}
                                                </span>
                                            </>
                                        ) : (
                                            server.status.numplayers + ' / ' + server.status.maxplayers
                                        )}
                                    </span>
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>

                    <div className='stat-container-flex'>
                        <Card className="stat-block-sm">
                            <CardBody>
                                <CardTitle id={`round-${index}`}>
                                    <RoundIconSmall/>
                                    <UncontrolledTooltip placement="top" target={`round-${index}`}>Current
                                        Round</UncontrolledTooltip>
                                </CardTitle>
                                <CardText>
                                    <span className="stat-value">
                                        {server.status.current_round}
                                    </span>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card className="stat-block-sm">
                            <CardBody>
                                <CardTitle id={`status-${index}`}>
                                    <ServerIconSmall/>
                                    <UncontrolledTooltip placement="top" target={`status-${index}`}>Server
                                        Status</UncontrolledTooltip>
                                </CardTitle>
                                <CardText>
                                        <span className='glow' style={{color: 'green', fontWeight: 'bolder'}}>
                                            ONLINE
                                        </span>
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                {playerName ? <p style={{textAlign: 'center', color: '#FFF'}}>{playerName} is playing on {server.name}</p>  : null}
            </div>
        </>
    );
}