import React from 'react';
import { 
    UncontrolledTooltip,
    Progress
} from 'reactstrap';

 export default function HonorProgress({playerData}) {
    let percent = playerData.scoreRemainder <= 0 ? 0 : (playerData.scoreRemainder / playerData.scoreNeeded) * 100
    return (
        <>
        <div className="mb-3 d-flex align-items-center justify-content-center">
            <div className="mt-2">
                <h3 className="yellow gunplay">{playerData.virtualHonor}</h3>
            </div>
            <div style={{ flex: 1, marginLeft: '1rem' }}>
                <UncontrolledTooltip placement="top" target="honorProgress">
                    {playerData.virtualHonor === 110 ? "Maxed!" : `${playerData.scoreRemainder} / ${playerData.scoreNeeded}`}
                </UncontrolledTooltip>
                <Progress
                    animated
                    id="honorProgress"
                    className="my-3"
                    color={playerData.virtualHonor === 110 ? "warning" : "info"}
                    max={playerData.virtualHonor === 110 ? 100 : playerData.scoreNeeded}
                    value={playerData.virtualHonor === 110 ? 100 : playerData.scoreRemainder <= 0 ? 0 : playerData.scoreRemainder}
                >
                    <span style={{fontWeight: 'bolder', fontSize: '1.25em', color: '#FFF', textShadow: 'black 0px 0px 10px'}}>{playerData.virtualHonor === 110 ? 100 : Math.trunc(percent)}%</span>
                </Progress>
            </div>
            <div className="mt-2" style={{marginLeft: '1rem'}}>
                <h3 className="yellow gunplay">{playerData.virtualHonor === 110 ? 110 : playerData.virtualHonor + 1}</h3>
            </div>
        </div>
        </>
    )
 }