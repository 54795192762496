import React, { useState, useEffect, useLayoutEffect } from 'react';
import Loading from '../Loading.js';
import { default as PlayersTable } from './PlayersTable';
import {getTrackerToken} from "../../utils/GetToken";
import {Container} from "reactstrap";

export default function Players() {
    const [playerCount , setPlayerCount] = useState(0);
    const [totalKillCount, setTotalKillCount] = useState(0);
    const [players, setPlayers] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const [queryParams, setQueryParams] = useState({
        offset: 0,
        sortField: "totalKills",
        sortDirection: -1
    });

    const onPaginate = (offset, sortField, sortDirection) => {
        setQueryParams({
            offset: offset,
            sortField: sortField,
            sortDirection: sortDirection
        });
    }
    
    let contents = !players && isLoading ? <Loading message={"Loading Players..."} /> : <><PlayersTable players={players} playerCount={playerCount} pageSize={pageSize} onPaginate={onPaginate} isLoading={isLoading} isError={isError} /></>;

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    useEffect( () => {
        async function fetchData() {
            try {
                const playerCount = await fetch(`api/data-overview/players`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${getTrackerToken()}`
                    }
                }).then(r => r.json());
                setPlayerCount(playerCount.playerCount);

                const totalKillCount = await fetch(`api/data-overview/kills`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${getTrackerToken()}`
                    }
                }).then(r => r.json());
                setTotalKillCount(totalKillCount.totalKills);
            }
            catch (ex) {
                console.log(ex);
            }
        }
        fetchData();
    }, [])

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                setIsError(false);
                
                let queryString = new URLSearchParams(queryParams).toString();
                const players = await fetch(`api/players/paginated?${queryString}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${getTrackerToken()}`
                    }
                }).then(r => r.json());
                setPlayers(players.players);
                setPageSize(players.pageSize);
            }
            catch (ex) {
                setIsLoading(false);
                setIsError(true);
                console.log(ex);
            }
        }
        
        fetchData().then(() => setIsLoading(false));
    }, [queryParams]);

    return (
        <Container>
            <div>
                <h1 className="page-header yellow gunplay">PLAYERS</h1>
                <p style={{color: 'white', fontSize: '1.0em'}}>Tracking <span
                    className="yellow gunplay">{playerCount}</span> Players with <span
                    className="yellow gunplay">{totalKillCount}</span> Kills</p>
                {contents}
            </div>
        </Container>
    );

}