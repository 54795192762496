import React, {useEffect, useState} from 'react';
import {Spinner, Toast, ToastBody, ToastHeader} from "reactstrap";
import {getTrackerToken} from "../../utils/GetToken";
import CheckIcon from "../SVG/CheckIcon";
import XIcon from "../SVG/XIcon";

const Status = () => {
    
    const [toastOpen, setToastOpen] = useState(true);
    const [status, setStatus] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                let token = getTrackerToken();

                const response = await fetch(`api/status`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(r => r.json());
                
                setStatus(response);
            }
            catch (ex) {
                setStatus(false);
            }
        }
        
        fetchData().then(() => setLoading(false));
    }, []);
    
    const onToggle= () => {
        setToastOpen(!toastOpen);
    }
    
    let icon = loading ? <><Spinner size="sm">Loading...</Spinner></> : status ? <CheckIcon /> : <XIcon />;
    let title = loading ? "AATracker Service Status..." : status ? "AATracker Service Online" : "AATracker Service Offline";
    let message = loading ? "Please wait while we check service status..." : status ? "We are actively tracking gameplay." : "Gameplay tracking will resume shortly.";

    return (
        <Toast isOpen={toastOpen} className="status-toast mb-2" style={{marginRight: '0.5rem', boxShadow: 'none', border: '1px solid #0f0f0f'}}>
            <ToastHeader icon={icon} toggle={onToggle} style={{textShadow: 'none'}}>
                {title}
            </ToastHeader>
            <ToastBody>
                {message}
            </ToastBody>
        </Toast>
    )
}
export default Status;