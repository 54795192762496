import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import PlayerSearch from "./PlayerSearch";

const SearchBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (location.pathname !== '/') {
      setQuery('');
    }
  }, [location.pathname]);

  const handleSelect = (selectedItem) => {
    setQuery(selectedItem.name);
    const path = `/player/${encodeURIComponent(selectedItem.name)}`;
    navigate(path);
  };
  
  return (
    <PlayerSearch handleSelect={handleSelect} query={query} setQuery={setQuery} />
  );
};

export default SearchBar;