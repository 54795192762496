import React from 'react';
import { default as getKDR } from './GetKDR';
import {
    Card,
    CardBody,
    CardTitle,
    CardText
} from 'reactstrap';
import CalendarIcon from "../SVG/CalendarIcon";
import ScoreIcon from "../SVG/ScoreIcon";
import TimeIcon from "../SVG/TimeIcon";
import CalcIcon from "../SVG/CalcIcon";
import DeathsIcon from "../SVG/DeathsIcon";
import KillsIcon from "../SVG/KillsIcon";


export default function MapListGroup({map}) {
    const hours = Math.floor(map.minutesPlayed / 60);
    const minutes = map.minutesPlayed % 60;
    return (
        <>
         <div className="stat-table-container" style={{overflow: 'hidden'}}>
            <div className='stat-container'>
                <Card className="stat-block disable-shadow">
                    <CardBody>
                        <CardTitle>
                            <KillsIcon />
                            <h5 className="mt-2 gunplay yellow">KILLS</h5>
                        </CardTitle>
                        <CardText>
                            <span className="stat-value">{map.kills}</span>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="stat-block disable-shadow">
                    <CardBody>
                        <CardTitle>
                            <DeathsIcon />
                            <h5 className="mt-2 gunplay yellow">DEATHS</h5>
                        </CardTitle>
                        <CardText>
                            <span className="stat-value disable-shadow">{map.deaths}</span>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="stat-block disable-shadow">
                    <CardBody>
                        <CardTitle>
                            <CalcIcon />
                            <h5 className="mt-2 gunplay yellow">KDR</h5>
                        </CardTitle>
                        <CardText>
                        <span className="stat-value disable-shadow">{getKDR(map.kills, map.deaths)}</span>
                        </CardText>
                    </CardBody>
                </Card>
            </div>
            <div className='stat-container'>
                <Card className="stat-block disable-shadow">
                    <CardBody>
                        <CardTitle>
                            <TimeIcon />
                            <h5 className="mt-2 gunplay yellow">TIME PLAYED</h5>
                        </CardTitle>
                        <CardText>
                            <span className="stat-value">{`${hours} ${hours > 1 || hours === 0 ? 'hours' : 'hour'} ${minutes} ${minutes > 1 || minutes === 0 ? 'minutes' : 'minute'}`}</span>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="stat-block disable-shadow">
                    <CardBody>
                        <CardTitle>
                            <ScoreIcon />
                            <h5 className="mt-2 gunplay yellow">SCORE</h5>
                        </CardTitle>
                        <CardText>
                            <span className="stat-value">{map.score}</span>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="stat-block disable-shadow">
                    <CardBody>
                        <CardTitle>
                        <CalendarIcon />
                            <h5 className="mt-2 gunplay yellow">LAST SEEN</h5>
                        </CardTitle>
                        <CardText>
                            <span className="stat-value">{map.lastSeenServer} <span className="date">- {map.lastSeen}</span></span>
                        </CardText>
                    </CardBody>
                </Card>
            </div>
        </div>
        </>
    )
}