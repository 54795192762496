import React, {useState, useEffect, useContext} from 'react';
import {
    Input,
    InputGroup,
    Dropdown,
    InputGroupText,
    DropdownMenu,
    DropdownItem,
    Spinner,
    UncontrolledTooltip
} from 'reactstrap';
import { PlayerDataContext } from '../App.js';

export default function PlayerSearch({handleSelect, query, setQuery, showTitle = true, placeHolder = "Enter a player name..."}) {
    const [filteredData, setFilteredData] = useState([]);
    const playerData = useContext(PlayerDataContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleInputChange = (event) => {
        if(!dropdownOpen) {
            setDropdownOpen(true);
        }
        const query = event.target.value.trim();
        setQuery(query);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        const filteredData = playerData?.filter((item) => {
            if (query === '') return false;
            let queryLower = query.toLowerCase();
            return !!(item.name.toLowerCase().includes(queryLower) || item.nameHistory?.some(name => name.toLowerCase().includes(queryLower)));

        }).slice(0, 15);
        setFilteredData(filteredData);
    }, [query, playerData]);
    
    return (
        <InputGroup>
            <Input
                type="text"
                placeholder={placeHolder}
                value={query}
                onChange={handleInputChange}
                className="search-input"
                style={{width: '25vw'}}
            />
            {showTitle ? <InputGroupText className="search-input">Search</InputGroupText> : null}
            {query?.length > 0 ? (
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction="down" className="dropdown-menu">
                    <DropdownMenu>
                        {!playerData ? <Spinner size="sm" color="light" style={{marginLeft: '1rem'}} /> :
                            filteredData?.length > 0 ?
                                filteredData?.map((item, index) => {
                                    const matchedName = item.nameHistory?.find(name => name.toLowerCase().includes(query.toLowerCase()));
                                    return (
                                        <DropdownItem className="search-item" key={index} onClick={() => handleSelect(item)} style={{ display: 'flex'}}>
                                            <span className="mr-3">{item.name}</span>
                                            {matchedName ?
                                                <>
                                                    <UncontrolledTooltip placement='right' target={`name-${index}`}>Alias: <span className="font-weight-bolder">{matchedName}</span></UncontrolledTooltip>
                                                    <span id={`name-${index}`} className='yellow fw-bolder'
                                                          style={{paddingRight: '0.5rem', paddingLeft: '0.5rem'}}>*</span>
                                                </>
                                                : null}
                                        </DropdownItem>
                                    );
                                }) : <span className="yellow" style={{paddingLeft: '1rem'}}>No Players Found</span>
                        }
                    </DropdownMenu>
                </Dropdown>
            ) : null}
        </InputGroup>
    );
}