import React from 'react';
import { Pagination, PaginationItem } from 'reactstrap';

const pageRange = 3;
const showPages = (pageRange * 2) + 1

export default function PaginationControl({currentPage, totalPages, handlePageChange, isLoading = false}) 
{
    let startPage = Math.max(1, currentPage - pageRange);
    let endPage = Math.min(totalPages, currentPage + pageRange);

    if (totalPages > showPages) {
        if (endPage - startPage < showPages) {
            if (endPage === totalPages) {
                startPage = endPage - showPages;
            }
            else {
                endPage = startPage + showPages;
            }
        }
    }
    else {
        endPage = totalPages;
        startPage = 1;
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }
    
    return (
        <Pagination className="pagination">
            <PaginationItem
                key="first"
                active={false}
                onClick={() => handlePageChange(1, totalPages)}
            >
                <span className={`page-link ${isLoading ? 'page-link-loading' : ''}`}>&lt;&lt;</span>
            </PaginationItem>
            <PaginationItem
                key="back"
                active={false}
                onClick={() => handlePageChange(currentPage - 1, totalPages)}
            >
                <span className={`page-link ${isLoading ? 'page-link-loading' : ''}`}>&lt;</span>
            </PaginationItem>
            {pageNumbers.map((pageNumber) => (
                <PaginationItem
                    key={pageNumber}
                    active={pageNumber === currentPage}
                    onClick={() => handlePageChange(pageNumber, totalPages)}
                >
                    <span className={`page-link ${isLoading ? 'page-link-loading' : ''}`}>{pageNumber}</span>
                </PaginationItem>
            ))}
            <PaginationItem
                key="next"
                active={false}
                onClick={() => handlePageChange(currentPage + 1, totalPages)}
            >
                <span className={`page-link ${isLoading ? 'page-link-loading' : ''}`}>&gt;</span>
            </PaginationItem>
            <PaginationItem
                key="last"
                active={false}
                onClick={() => handlePageChange(totalPages, totalPages)}
            >
                <span className={`page-link ${isLoading ? 'page-link-loading' : ''}`}>&gt;&gt;</span>
            </PaginationItem>
        </Pagination> 
    );
}
