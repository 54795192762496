import React, { useState, useEffect } from 'react';
import Loading from '../Loading.js';
import { getTrackerToken } from '../../utils/GetToken.js';
import { default as ServersTable } from './ServersTable.js';
import Flags from 'country-flag-icons/react/3x2'
import {Container} from "reactstrap";

export default function Servers() {
    const [servers, setServers] = useState([]);
    const [offlineServers, setOfflineServers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsError(false);
                setLoading(true);
                
                const data = await fetch('api/servers', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${getTrackerToken()}`
                    }
                }).then(r => r.json());

                let offlineServers = [];
                const onlineServers = data.filter(server => {
                    if (server.status === null || server.players === null) {
                        offlineServers.push(server);
                        return false;
                    }
                    return true;
                });

                onlineServers.forEach(server => server.countryComponent = Flags[server.country]);

                setServers(onlineServers);
                setOfflineServers(offlineServers);
            }
            catch (ex) {
                setLoading(false);
                setIsError(true);
                console.log(ex);
            }
        }
        fetchData().then(() => setLoading(false));
    }, []);
      
    let contents = loading ? (
        <Loading message={"Querying Servers..."} />
    ) : ( servers && offlineServers && (
        <ServersTable servers={servers} offlineServers={offlineServers} /> )
    );

    let totalPlayers = servers.reduce((sum, server) => sum + parseInt(server.status.numplayers), 0);

    return (
        <Container>
            <div style={{marginBottom: '2rem'}}>
                <h1 className="page-header yellow gunplay">SERVERS</h1>
                <p style={{color: 'white', fontSize: '1.0em'}}>Tracking <span
                    className='yellow gunplay'>{servers.length}</span> online servers with <span
                    className='yellow gunplay'>{totalPlayers}</span> players in-game!</p>
                {isError || (!servers && !loading) || (servers.length === 0 && !loading) ?
                    <>
                        <div className="center mt-4">
                            <h5 style={{color: "#FFF", fontWeight: "bold"}}>Whoops!</h5>
                            <h6 style={{color: "#FFF"}}>We were unable to load any America's Army servers. Please try
                                again later.</h6>
                        </div>
                    </>
                    : contents}
            </div>
        </Container>
    );
}