export default function sortPlayers(players, sortDirection, sortColumn) {
    return players.slice().sort((a, b) => {
        switch (sortColumn) {
            case "name":
                return sortDirection * a.name.localeCompare(b.name);
            case "honor":
                return sortDirection * (a.honor - b.honor);
            case "vHonor":
                let difference = (a.virtualHonor - a.honor) - (b.virtualHonor - b.honor);
                if (difference === 0) {
                    let scoreDifference = a.score - b.score;
                    return sortDirection * (scoreDifference);
                }
                return sortDirection * (difference);
            case "kills":
                return sortDirection * (a.kills - b.kills);
            case "deaths":
                return sortDirection * (a.deaths - b.deaths);
            case "kdr":
                const aKdr = a.deaths !== 0 ? a.kills / a.deaths : a.kills;
                const bKdr = b.deaths !== 0 ? b.kills / b.deaths : b.kills;
                return sortDirection * (aKdr - bKdr);
            case "score":
                return sortDirection * (a.score - b.score);
            case "time":
                return sortDirection * (a.minutesPlayed - b.minutesPlayed);
            default:
                return 0;
        }
    });
}