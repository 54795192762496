import React from 'react';
import Chart from "react-apexcharts";

export default function KillsChart({mapData}) {
    const filteredMapData = mapData.filter(mapData => mapData.kills > 0)
    filteredMapData.sort((a, b) => b.kills - a.kills);

    const options = {  
        chart: {
            type: "pie",
            foreColor: "#FFFFFF", 
        },
        labels: filteredMapData.map(({ map }) => (map)),
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    minAngleToShowLabel: 23
                }
            }
        }
    };

    const series = filteredMapData.map(({ kills }) => (kills));

    return(
        <div className="chart-wrapper mt-3">
            <h6>Kills</h6>
            {filteredMapData.length
                ? <Chart options={options} series={series} type="pie"/>
                : <p className="color">No data to display</p>
            }
        </div>
    )
}