import React, {useEffect, useState} from 'react';
import { default as MapListGroup } from './MapListGroup';
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem, Input,
    UncontrolledAccordion,
    UncontrolledTooltip,
} from 'reactstrap';

export default function MapStats({mapData}) {
    
    const [filter, setFilter] = useState("");
    const [maps, setMaps] = useState(mapData);
    
    const onInputChange = (event) => {
        setFilter(event.target.value.trim());
    };
    
    useEffect(() => {
        const data = mapData.filter(map => map.map.toLowerCase().includes(filter.toLowerCase()));
        setMaps(data);
    }, [filter]);
    
    return (
        <div>
            <div className="d-flex justify-content-center">
                <h2 className="profile-sub-heading yellow gunplay">MAPS ({maps.length})</h2>
            </div>
            <div className="w-100 d-flex justify-content-center">
                <Input
                    type="text"
                    placeholder="Enter a map name..."
                    onChange={onInputChange}
                    className="search-input map-filter"
                />
            </div>
            {maps.map((map, index) => (
                <UncontrolledAccordion className='mt-2' key={`accordion-${index.toString()}`} open='false'>
                    <AccordionItem>
                        <AccordionHeader targetId={index.toString()}>
                            <UncontrolledTooltip
                                placement="top"
                                target={'lb' + index}
                            >
                                View Leaderboard
                            </UncontrolledTooltip>
                            <a className="link-no-shadow" id={'lb' + index}
                               href={`/map/${encodeURIComponent(map.map)}`}>{map.map}</a>
                        </AccordionHeader>
                        <AccordionBody accordionId={index.toString()}>
                            {<MapListGroup map={map}/>}
                        </AccordionBody>
                    </AccordionItem>
                </UncontrolledAccordion>
            ))}
        </div>
    )
}