import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import ArrowIcon from '../SVG/ArrowIcon.js';
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardText,
    Alert,
    Table, UncontrolledAlert
} from 'reactstrap';
import MapIcon from "../SVG/MapIcon";
import PlayersIcon from "../SVG/PlayersIcon";
import TimeIcon from "../SVG/TimeIcon";
import CalendarIcon from "../SVG/CalendarIcon";

export default function SessionInfo({isValid, sessionData}) {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    if (!isValid) {
        return (
            <>
                <div className="d-flex align-items-center">
                    <a className="backbtn" onClick={goBack}>
                        <ArrowIcon/>
                    </a>
                    <h1 className="page-header yellow gunplay mt-2" style={{marginLeft: '10px'}}>SESSION NOT FOUND</h1>
                </div>
                <div>
                    <Alert color="danger" className="mt-2">
                        Whoops, there appears to be no results for that match.
                    </Alert>
                </div>
            </>
        )
    }

    return (
        <>
        {sessionData.map.endsWith("SFMod") ? 
        <>
            <UncontrolledAlert color="danger">
                Our records indicate this server was running a modded map during this session. Stats are not tracked.
            </UncontrolledAlert>
        </>
        : null}
        <Card
            className="my-2"
            color="dark"
            inverse
            style={{
                width: '100%',
                minHeight: '50vh'
            }}
        >
            <CardBody>
                <div className="d-flex w-100">
                    <a className="backbtn mt-1" onClick={goBack}>
                        <ArrowIcon className="d-flex align-self-start"
                                   style={{flex: '1', flexBasis: '0'}}/>
                    </a>
                    <div className="d-flex align-items-center justify-content-center"
                         style={{flex: '1', flexBasis: '0'}}>
                        <div className="d-flex flex-column">
                            <h1 className="mt-1 yellow gunplay page-header"
                                style={{marginLeft: '10px'}}>{sessionData.map.toUpperCase()} {sessionData.date}</h1>
                            <div className="d-flex align-items-center justify-content-center">
                                <Link to={`/server/${encodeURIComponent(sessionData.address)}`}>{sessionData.hostName}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stat-table-container mt-3">
                <div className='stat-container'>
                        <Card className="stat-block">
                            <CardBody>
                                <CardTitle>
                                    <MapIcon/>
                                    <h5 className="mt-2 yellow gunplay">MAP</h5>
                                </CardTitle>
                                <CardText>
                                    <Link className="stat-value-link"
                                          to={`/map/${encodeURIComponent(sessionData.map)}`}>{sessionData.map}</Link>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card className="stat-block">
                            <CardBody>
                                <CardTitle>
                                    <PlayersIcon/>
                                    <h5 className="mt-2 yellow gunplay">PLAYERS</h5>
                                </CardTitle>
                                <CardText>
                                    <span className="stat-value">{sessionData.players.length}</span>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card className="stat-block">
                            <CardBody>
                                <CardTitle>
                                    <TimeIcon/>
                                    <h5 className="mt-2 yellow gunplay">DURATION</h5>
                                </CardTitle>
                                <CardText>
                                    <span className="stat-value">{sessionData.minutesPlayed} minutes</span>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card className="stat-block">
                            <CardBody>
                                <CardTitle>
                                    <CalendarIcon/>
                                    <h5 className="mt-2 yellow gunplay">DATE</h5>
                                </CardTitle>
                                <CardText>
                                    <span className="stat-value">{sessionData.date}</span>
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-3 mb-3">
                    Players
                </div>
                <div className="table-wrapper">
                    <Table striped>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Kills</th>
                            <th>Deaths</th>
                            <th>Goal</th>
                            <th>Leader</th>
                            <th>ROE</th>
                            <th>Score</th>
                        </tr>
                        </thead>
                        <tbody>
                        {sessionData.players.sort((a, b) => b.score - a.score).map(player => (
                            <tr key={player.name}>
                                <td><Link
                                    to={`/player/${encodeURIComponent(player.name)}`}>{player.name}</Link>
                                </td>
                                <td>{player.kills}</td>
                                <td>{player.deaths}</td>
                                <td>{player.goal}</td>
                                <td>{player.leader}</td>
                                <td>{player.roe}</td>
                                <td>{player.score}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </CardBody>
        </Card>
        </>
)
}